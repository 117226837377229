<template>
  <b-sidebar
    id="add-new-brands-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Tạo mới Nhóm quản lý</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group label="Nhóm quản lý" label-for="name">
              <b-form-input
                id="name"
                v-model="categoryData.name"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="Nhập nhóm quản lý"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0]?'Bạn cần điền nhóm quản lý':'' }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >{{isUpdate?'Cập nhật':'Tạo'}}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >Huỷ</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active"
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true
    },
    isUpdate: Boolean,
    isLoading: Boolean,
    dataProp: Object
  },
  data() {
    return {
      required
    };
  },
  watch: {
    dataProp: {
      handler() {
        this.categoryData.name = this.dataProp?.name || "";
      },
      deep: true
    }
  },
  mounted() {
    this.categoryData.name = this.dataProp?.name || "";
  },
  setup(props, { emit }) {
    const toast = useToast();
    const blankCategory = {
      name: ""
    };

    const categoryData = ref(JSON.parse(JSON.stringify(blankCategory)));
    const resetuserData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategory));
    };

    const onSubmit = () => {
      const payload = {
        ...categoryData.value
      };
      props.isUpdate
        ? emit("onUpdate", { ...payload, id: props.dataProp.id })
        : emit("onAdd", payload);
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    return {
      toast,
      categoryData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm
    };
  }
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-brands-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
